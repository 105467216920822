@import '../../helpers/styles/mixins.scss';

button.button {
  background-color: #ffa841;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: -0.5px !important;
  border-radius: 4px;
  padding: 5px 10px !important;
  min-height: 30px;
  outline-width: 0px;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  @include Responsive("mobile"){
    padding: 2px 10px !important;
    font-size: 14px !important;
  }

}
