@import "../../helpers/styles/constants.scss";
@import "../../helpers/styles/mixins.scss";

.card {
  padding: 20px 20px;

  &.hoverable{
    &:hover{
      background:  $blue_opaque !important;
    }
  }
}

.gettingStarted__card {
  border: 2px solid $blue;

  @include Responsive("tab") {
    margin: 10px auto !important;
    width: 80% !important;
  }
}

.product__Card {
  @include Responsive("tab") {
    width: 90%;
  }

  &.selected {
    border: 2px solid $blue;
  }

  div.tag {
    background-color: transparent;

    p.tag__text {
      margin-top: -95px;
      transform: rotate(-39deg);
    }
  }
}

.shift__top {
  margin-top: -120px;
}

.mainProduct__card {
  @include Responsive("tab") {
    flex-direction: column;
    div.imageContainer {
      margin-right: 0px !important;
      margin-bottom: 10px;
      width: 100% !important;
      min-height: 177px;
    }

    div.content {
      min-width: 100%;
    }
  }

  div.imageContainer {
    width: 290px;
    min-width: 290px;
    height: 177px;
    object-fit: contain;
  }

  &.small {
    div.imageContainer {
      height: 118px;
      width: 178.56px;
      min-width: 178.56px;
    }

    p.description {
      -webkit-line-clamp: 3;
      max-height: 54px;
      min-height: 40px;
      height: auto;
    }


  }

  &.small.col {
    div.imageContainer {
      min-height: 100px !important;
    }
  }

  &.col{
    p.description {
      height: auto !important;
      max-height: 65px !important;
      min-height: 40px;
    }
  }

  p.description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 65px;
    min-height: 40px;
    height: auto;
  }

  p.description * {
    font-size: 13px !important;
    line-height: 16px !important;
    margin: 0;
  }
}

.infoCard {
  &.error {
    border: 1px solid $red;
    background-color: $red_opaque !important;
  }

  &.success {
    border: 1px solid $green;
    background-color: $green_opaque !important;
  }

  &.primary {
    border: 1px solid $blue;
    background-color: $blue_opaque !important;
  }

  &.warning {
    border: 1px solid $orange;
    background-color: $orange_opaque !important;
  }
}

.prepr-unified-integration-card {
  position: relative;
  display: flex;
  height: 100%;
  padding: 12px 14px;
  border-radius: 5px;
  border: 1px solid $light_gray;
  gap: 12px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);

  .link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .logo {
    height: 50px;
  }

  .details {
    .title {
      font-size: 16px;
      margin-bottom: 8px;
    }

    .badge-container {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      align-items: center;

      .badges,
      .badges-success {
        font-size: 11px;
        padding: 2px 4px;
        border-radius: 5px;
      }

      .badges {
        border: 1px solid $gray;
        background: rgba($gray, 0.1);
      }

      .badges-success {
        border: 1px solid $success;
        color: $success;
        background: rgba($success, 0.1);
      }
    }
  }
}

.live-event-details {
  margin: 2em 0;
  display: flex;
  align-items: flex-start;
  gap: 2em;

  .details {
    h3 {
      font-size: 14px;
    }

    ul {
      display: flex;
      align-items: center;
      list-style: none;
      justify-content: space-between;
      padding: 0;
      gap: 1em;
      margin: 0;

      li {
        font-size: 14px;
      }
    }
  }
}

.resource-course-card {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  @include Responsive("tab") {
    min-height: 300px;
  }
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  &--overlay {
    width: 100%;
    background: white;
    padding: 16px;

    &--title {
      font-size: 20px;
      color: $blue;
      text-align: center;
      margin: 16px 0;
    }

    &--play-button {
      font-size: 16px;
      color: $blue;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
