@import "../../helpers/styles/constants.scss";
@import "../../helpers/styles/mixins.scss";

.timeline_form {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__restricted {
    background: $bg_gray;
    border-radius: 10px;
    padding: 10px 15px;
  }
}

.associatedLabs__listContainer_new {
  max-height: 600px;
  overflow-y: auto;
  padding: 10px;

  @include Responsive("mobile") {
    max-height: none !important;
  }

  .items {
    gap: 16px;
  }

  h3 {
    color: $blue;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    @include Responsive("mobile") {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.challenge_form {
  .associatedLabs__selectedContainer {
    max-height: 744px;
    overflow-y: auto;
    padding: 10px 0;
    .associatedLabs__image_container {
      flex-shrink: 0;
      flex-basis: 190px;
      svg {
        fill: $blue;
        margin: 0 10px 0 0;
      }
    }
  }

  .toggle_card_wrapper {
    .title,
    .description {
      font-weight: 600;
    }
    .card_title {
      font-size: 22px;
    }
    .card_description {
      font-size: 20px;
    }

    .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
      box-shadow: none !important;
      .card_button {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .css-15v22id-MuiAccordionDetails-root {
      padding: 0px !important;
    }
  }

  .task_question {
    &__wrapper {
      p {
        color: #5b5d6b;
        font-size: 12px;
        padding-bottom: 10px;
        line-height: 14px;
      }
    }
    &__container {
      background-color: $bg_gray;
      padding: 16px 32px 16px 16px;
      border-radius: 6px;
      svg {
        margin-top: 22px;
        path {
          fill: $blue;
        }
      }
    }
    &__btn {
      &_delete {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20px;
        width: 20px;
        background: transparent;
        border: 2px solid #db3353;
        color: #db3353;
        font-size: 14px;
        font-weight: bold;
        border-radius: 4px;
        margin-top: 22px;
        &:hover {
          background: #db3353;
          color: white;
        }
      }
    }
  }
}
