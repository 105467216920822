@import '../../helpers/styles/constants.scss';
@import "../../helpers/styles/mixins.scss";

.uppy-Dashboard-inner {
    background-color: #efefef !important;
    border: 1px solid #efefef !important;
    height: 270px !important;
    width:100%!important;

    @include Responsive('mobile') {
        width: 100% !important;
        height: auto !important;
    }
}

.uppy-Dashboard-AddFiles-list {
    overflow-Y: auto !important;
}
.uppy-size--md .uppy-Dashboard-AddFiles-list {
    height:200px!important;
}

.uppy-Dashboard-AddFiles-title {
    font-size: 18px !important;
    margin-top: 30px !important;

    @include Responsive('mobile') {
        font-size: 14px !important;
    }
}

.uppy-DashboardTab-btn {
    height: auto !important;
}

a.uppy-Dashboard-poweredBy {
    display: none !important;
}