@import "../../helpers/styles/constants.scss";
@import "../../helpers/styles/mixins.scss";

.pdfView {
  height: 144px;
  width: 120px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .icon {
    font-size: 50px;
    color: $blue;
  }

  .cross {
    position: absolute;
    top: -10px;
    right: -5px;
    height: 18px;
    width: 18px;
    background-color: $red;
    color: white;
    border-radius: 100%;
  }

  p {
    color: #9d9d9d;
    width: 50%;
  }
}

.title__btn {
  position: absolute;
  right: 0;
  top: 5px;
}

.trophyList__card {
  background-color: $blue_opaque;
  border-radius: 10px;
}

// associated listing css

.associatedLabs__listContainer {
  max-height: 600px;
  overflow-y: auto;
  padding: 5px;

  @include Responsive("mobile") {
    max-height: none !important;
  }

  h3 {
    color: $blue;

    @include Responsive("mobile") {
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.miniOnboarding {
  background: linear-gradient(
    to bottom,
    $light_gray 160px,
    white 0%,
    white 73%
  );
  min-height: 600px;

  &.challenge,
  &.guidelines {
    background: linear-gradient(
      to bottom,
      $green_opaque 160px,
      white 0%,
      white 73%
    );
  }

  &.resource {
    background: linear-gradient(
      to bottom,
      $purple_opaque 160px,
      white 0%,
      white 73%
    );

    @include Responsive("mobile") {
      background: linear-gradient(
      to bottom,
      $purple_opaque 180px,
      white 0%,
      white 73%
    );
    }
  }

  &.outcome {
    background: linear-gradient(to bottom, $yellow 160px, white 0%, white 73%);
  }

  &.lab {
    background: linear-gradient(
      to bottom,
      $light_blue 160px,
      white 0%,
      white 73%
    );

    @include Responsive("mobile") {
      background: linear-gradient(
      to bottom,
      $light_blue 200px,
      white 0%,
      white 73%
    );
    }
  }

  &.organization {
    background: linear-gradient(to bottom, $yellow 160px, white 0%, white 73%);
  }

  .card {
    border: 2px solid $light_gray;
    border-radius: 10px;
    padding: 12px 8px;
    cursor: pointer;

    &.selected {
      background-color: $light_gray;
    }

    &.lab {
      border: 2px solid $light_blue;

      &.selected {
        background-color: $light_blue;
      }
    }

    &.challenge {
      border: 2px solid $green_opaque;

      &.selected {
        background-color: $green_opaque;
      }
    }

    &.resource_module {
      border: 2px solid $purple_opaque;

      &.selected {
        background-color: $purple_opaque;
      }
    }

    &.acheivement {
      border: 2px solid $yellow;

      &.selected {
        background-color: $yellow;
      }
    }
  }
}


.timeline {
    .timeline__point
    {
      position: relative;
      &:not(:last-child)::after {
          content: '';
          position: absolute;
          background-color: $blue;
          width: 100%;
          height: 4px;
          top: 0;
          left:0;
          z-index: 0;
      }
      >p{
        color: $light_gray ;
      }
  }
}