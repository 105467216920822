.chat {
    position: fixed;
    bottom: 0px;
    z-index: 99999;
    right: 100px;
    width: 550px;
    background-color: white;
    border-radius: 10px 10px 0px 0px;
    box-shadow: 1px 3px 11px #9a9a9ac2;
    transition: all 0.5s linear;

    &.close {
        max-height: 36px;
        max-width: 220px;

        .header {
            cursor: pointer;
            padding: 6px 10px !important;

            h3 {
                font-weight: 500;
                font-size: 17px;
            }
        }
    }

    .header {
        background-color: #009ed2;
        color: white;
        border-radius: 10px 10px 0px 0px;
        overflow: hidden;
    }

    .chats {
        min-height: 600px;
        max-height: 600px;
        background-image: url(https://img.freepik.com/premium-vector/social-networks-dating-apps-vector-seamless-pattern_341076-469.jpg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        overflow-y: auto;
        padding: 10px 10px;
        border: 1px solid #eee;

        .loading {
            font-weight: bold;
            display: inline-block;
            font-family: monospace;
            font-size: 24px;
            line-height: 100%;
            clip-path: inset(0 3ch 0 0);
            animation: l 1s steps(4) infinite;
            color: #6b6b6b;
        }

        @keyframes l {
            to {
                clip-path: inset(0 -1ch 0 0)
            }
        }

        .message {
            display: flex;

            p {
                padding: 10px;
                max-width: 350px;
                border-radius: 10px;
                box-shadow: 1px 2px 3px #929292ab;
                font-weight: 400;
            }

            &.left {
                p {
                    background-color: #d7f2fb;
                    color: black;
                }
            }

            &.right {
                flex-direction: row-reverse;

                p {
                    background-color: #f3f3f3;
                    color: black;
                }
            }
        }
    }

    .footer {
        padding: 5px 10px;

        input {
            border: none;
            outline: none;
            flex:1;
        }
    }
}