@import '../../helpers/styles/constants.scss';
@import '../../helpers/styles/mixins.scss';

.filterBox{
    &--title{
     width: 100%;
     color:white;
     background-color: $blue;
     display: flex;
     align-items: center;
     height: 25px;
     text-transform: uppercase;
    } 
    &--content{
        padding: 10px 15px;
    }
 }

.achievement__box {
    width: 75vw;
    max-width: 1200px;

    @include Responsive("tab") {
        width: 100%;
    }

    div.certificate {

        &--card-0,
        &--card-1,
        &--card-2 {
            border: 1px solid gray;
            width: 100%;

            &-Right {
                width: 60%;
            }

            @include Responsive("mobile") {
                height: fit-content;

                &-Right {
                    width: 95% !important;
                    margin-top: 20px;
                }
            }
        }

        &--card-1 {
            flex-direction: column;
            border: 4px solid #83D0A4 !important;

            &-Content {
                width: 50%;
                display: flex;

                @include Responsive("tab") {
                    width: 95%;
                    margin-top: 20px;
                }
            }
        }

        &--card-2 {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
}