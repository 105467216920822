@import "../../helpers/styles/mixins.scss";
@import '../../helpers/styles/constants.scss';

.slides {
  max-width: 700px;
  height: 450px;

  @include Responsive("mobile") {
    height: 320px;
    width: 95%;
  }
}

.slider-dots {

  .dot {
    height: 15px;
    width: 15px;
    background: $light_gray;
    transition: all 0.2s linear;

    &.dot-selected {
      transform: scale(1.1);
    }

    &.dot.white {
      height: 11px !important;
      width: 11px !important;
    }

    @include Responsive("mobile") {
      height: 10px;
      width: 10px;
    }
  }
}