$blue: #498cce;
$green: #27ae60;
$success: #1bc459;
$red: #fd5959;
$yellow: #FFBC3F;
$orange:#FFA800;
$red_opaque: #ffeaea;
$green_opaque: #c7ead7;
$blue_opaque: #d6f2fe;
$bg_gray: #f3f7fc;
$gray: #c4c4c4;
$light_gray: #f3f4f8;
$darkgray: #9496a1;
$purple: #9e31e1;
$light_blue: #268bd333;
$dark_blue: #0a2b77;
$light_yellow: #fffcf3;
$purple_opaque: #e4ccff;
$orange_opaque: #FFF0DE;
