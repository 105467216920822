@import "../../helpers/styles/constants.scss";
@import "../../helpers/styles/mixins.scss";

.input,
.select,
.textarea {
  border: 1px solid #eee !important;
  border-radius: 6px !important;
  outline: none !important;
  transition: all 0.2s !important;
  padding-left: 5px;
  font-size: 14px !important;
  box-shadow: none !important;

  fieldset {
    border: none !important;
  }

  &.error {
    border: 1px solid $red !important;
    background: rgba(248, 157, 157, 0.034);
  }

  &:focus {
    border: 2px solid #4d8fce !important;
    box-shadow: 1px 2px 6px #eee !important;
  }
}

.input {
  width: 300px;
  height: 40px;
  max-width: 100% !important;
}

input.gray {
  border: none !important;
  background: #f5f5f5 !important;
}

div.preInput {
  border: 2px solid gray;
  background: #f5f5f5;
  width: fit-content;
  padding: 0 10px;
  font-weight: 500;
  display: grid;
  place-items: center;
}

.select {
  width: 305px;
  background: #ffffff;
  min-height: 40px;
}

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inputContainer > p.error {
  color: $red;
  font-weight: 500;
  font-size: small;
  margin: 2px;
}

.inputContainer > p.title {
  font-weight: 500;
  opacity: 0.9;
  font-size: 14px;
  margin-bottom: 5px;
}

.inputContainer > p.description {
  font-size: 12px;
  color: $darkgray;
  font-style: normal;
  margin-bottom: 5px;
}

.inputContainer:has(.description) > p.title {
  margin-bottom: 0px;
}

.inputContainer > p.title > span {
  color: $red;
  font-weight: 500;
  margin-left: 3px;
}

input.bordered > .MuiSelect-icon {
  width: 0.8em !important;
  margin-right: 10px !important;
  height: 0.8em !important;
  color: black !important;
  border: 2px solid black !important;
  border-radius: 100% !important;
}

.inputContainer > span.icon {
  position: absolute;
  color: gray;
  right: 6px;
  display: flex;
  width: 30px;

  &.left {
    left: 6px !important;
  }
}

.dropbox__input {
  border: 3px dashed rgb(164, 164, 164);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;

  p {
    font-weight: 600;
    color: gray;
    width: 80%;

    span {
      color: $blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

p.error,
p.helperText {
  color: $red;
  font-weight: 500;
  font-size: small;
  margin: 2px;
}

p.helperText {
  color: $darkgray !important;
  font-weight: 400;
}

.calenderBox {
  border-radius: 5px !important;
  margin-top: 5px !important;
  border: 1px solid #eee !important;
  box-shadow: 1px 2px 6px #eee !important;
}
