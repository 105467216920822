@import '../../helpers/styles/constants.scss';
@import '../../helpers/styles/mixins.scss';

.dashboardLeft {
  width: 94.5%;

  @include Responsive('tab') {
    width: 100%;
  }
}

.dashboardRight {
  width: 97.5%;

  @include Responsive('tab') {
    width: 100%;
  }
}

.projectCardText p {
  font-size: 14px !important;
  opacity: 0.6;
  max-width: 100%;

  @include Responsive('tab') {
    font-size: 12px !important;
    letter-spacing: -0.5px;
    max-width: 200px;
  }
}

.statusChip {
  font-size: 14px;
  font-family: 500;
  padding: 3px 8px;
  border-radius: 20px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: -0.5px;
  height: 23px;
  width: fit-content;
  &.one {
    color: rgba(255, 149, 24, 1);
    background: rgba(255, 240, 222, 1);
  }

  &.two {
    color: rgba(73, 140, 206, 1);
    background-color: #E7EFF8;
  }

  &.three {
    color: rgba(39, 174, 96, 1);
    background: #E6F9EE;
  }
  @include Responsive("mobile"){
    font-size: 12px;
    letter-spacing: -0.8px;
    height: 20px;
  }
}