@import '../../../../helpers/styles/constants.scss';

.airmeet {
  .lab-live-event-schedule {
    .schedule-button-prev {
      top: 50%;
      left: -30px;
      transform: translateY(-90%);
    }

    .schedule-button-next {
      top: 50%;
      right: -30px;
      transform: translateY(-90%);
    }

    .schedule-button-prev, .schedule-button-next {
      position: absolute;
      cursor: pointer;
      width: 40px;
      height: 40px;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &--title {
      font-size: 20px;
      color: black;
      margin: 0 30px;
      font-weight: bold;
    }

    &--tab {
      margin: 0 20px;
    }

    .active {
      color: white;
      background: $blue;

      .lab-live-event-schedule-tab--list-month {
        color: white;
      }
    }

    &-tab--list {
      padding: 0.6em;
      margin: 10px;
      border-radius: 10px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &-month {
        font-size: 16px;
        transition: all 0.1s ease-in-out;
      }

      &-date {
        font-size: 20px;
        margin-right: 2px;
        font-weight: bold;
      }

      &-day {
        font-size: 14px;
      }

      &:hover {
        background: $blue;
        color: white;

        .lab-live-event-schedule-tab--list-month {
          color: white;
        }
      }
    }

    .add-calendar {
      height: 25px;
    }

    &--sessions-preview {
      padding: 1.5em;

      &--timing {
        font-size: 16px;
        text-transform: uppercase;
      }

      &--details {
        display: flex;
        align-items: center;
        padding: 1em;
        border: 1px solid #E5E5E5;
        border-radius: 3px;

        &--title {
          font-size: 16px;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .user {
          display: flex;
          gap: 8px;
          align-items: center;

          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          p {
            margin: 0;
            font-size: 14px;
          }
        }
      }
    }
  }

}
