@import '../../../helpers/styles/constants.scss';
@import '../../../helpers/styles/mixins.scss';

.mainPage__layout {
    display: flex;
    margin: 0 auto 20px auto;
    width: 80%;
    justify-content: center;
    max-width: 1300px;

    @include Responsive("tab"){
        flex-direction: column;
        width: 95%;
    }

    .left {
        flex: 0.25;
        min-width: 350px;
        @include Responsive("tab") {
            flex: 1;
            min-width:auto!important;
        }
    }

    .right {
        flex: 0.75;
        margin-left: 30px;

        @include Responsive("tab") {
            margin-left: 0;
            flex: 1;
            margin-top: 20px;
        }

    }
}