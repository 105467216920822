@import '../../helpers/styles/constants.scss';
@import '../../helpers/styles/mixins.scss';

.labtype__card{
    cursor: pointer;
    &.selected{
        border:1px solid $blue !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
            0px 0px 10px $blue !important;
    }
}