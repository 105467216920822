@import '../../helpers/styles/constants.scss';
@import '../../helpers/styles/mixins.scss';

.gettingStartedDiv__org{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    @include Responsive("tab"){
        padding-top: 50px;
    }

    h1{
        font-size: 44px;
    }
}
.featureCards__grid{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto !important;
    max-width: 95vw;
    @include Responsive("tab"){
        display: flex !important;
        align-items: center;
        flex-direction: column;
    }
    div.feature__Card{
        margin: 20px !important;
    }
}

.labs__productCardGrid{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    @include Responsive("tab"){
        flex-direction: column;
        margin: 0 auto;
        flex-wrap: nowrap;
    }
}

.org__checkoutCardsContainer{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    @include Responsive("tab"){
        flex-direction: column;
        width: 100%;
        align-items: center;
        button {
            font-size: 12px !important;
        }
    }
    div.checkout__left{
        flex:1;
        @include Responsive("tab"){
            width: 90%;
        }
    }
    div.checkout__right{
        margin-left: 20px !important;
        @include Responsive("tab"){
            margin: 10px auto;
            width: 90% !important;
            margin-left: 0px !important;
        }
    }
}

.footer__btns {
    display: flex;
    align-items: center;
    button{
    @include Responsive("tab") {
        font-size: 12px !important;
    }}
}

.organization__subscriptionPlansGrid{
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 30px 0;
    @include Responsive("tab"){
        flex-direction: column;
    }
    div.subscriptionPlan{
        margin: 0 20px;
        text-align: center;
        padding: 20px 40px 60px 40px;
        border-radius: 10px;
        cursor: pointer;
        &.selected{
            background-color: $blue_opaque;
        }
        h3{
            color:$blue;
            margin-top: 20px;
        }
        p{
            margin-top: 50px;
            color:gray;
            span{
                color:$blue;
                font-size: 30px;
                font-weight: 600;
            }
        }
        li{
            font-size: 14px;
            margin-top: 5px;
            color:gray;
        }
    }
}

.organization__Addons{
    margin-left: 50px;
    @include Responsive("tab"){
     margin-left: 0px !important;  
     font-size: 12px !important;
    }
}

.orgType__grid {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px auto;
    max-width: 850px;
    div.orgType__box {
        margin: 10px;
        opacity: 0.5;
        cursor: pointer;
        div.orgType__ImageBox{
            height: 150px;
            width: 150px;
            border-radius: 5px;
            overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        }
        &.selected {
            opacity: 1;
        }
    }
}

.editOrganization{
    background-color:$bg_gray;
    @include Responsive("tab"){
        h1{
            font-size: 20px;
        }
        .editOrg__imageDropCard{
                flex-direction: column;
                .ml-10{
                    margin-left: 0px !important;
                    margin-top: 20px;
                }
                .res__banner{
                    height: 200px !important;
                    width: 90% !important;
                }
        }
    }
    .input__row {
        width: 100%;
        margin-top: 20px;
        display: flex !important;
        @include Responsive("tab"){
            flex-direction: column;
            .ml-2{
             margin-left: 0px !important;
            }
            .w-50p{
                width: 100% !important;
            }
        }
    }
    .addMemberRow__org {
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: space-between;
        &Left{
           flex:1;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &Right{
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex:1;
            margin-left: 50px;
        }
        @include Responsive("tab"){
            flex-direction: column;
            &Left {
              flex-direction: column;
            }
            
            &Right {
               margin-left: 0px;
               margin-top:10px;
            }
        }
    }
}

.loginCustomization__org{
    position: relative;
    overflow: hidden;
    .locked__overlay{
        height: 100%;
        width: 100%;
        top:0;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.427);
        border-radius: 10px;
        display: grid!important;
        place-items: center;
    }
}