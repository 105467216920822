@import "./helpers/styles/mixins.scss";
@import "./helpers/styles/constants.scss";

@include Spacings;
@include Sizing;
@include Opacity;
@include HWs;

* {
  box-sizing: border-box;
}

body {
  background-color: $bg_gray;
}

.app {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.h-screen {
  height: 100dvh;
}

.w-screen {
  width: 100dvw;
}

.m-0 {
  margin: 0px;
}

.ml-auto {
  margin-left: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.p-0 {
  padding: 0px !important;
}

.p-0--mob {
  @include Responsive("mobile") {
    padding: 0px !important;
  }
}

.flex {
  display: flex;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-reverse {
  display: flex;
  flex-direction: row-reverse !important;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch !important;
}

.items-left {
  align-items: flex-start !important;
}

.items-right {
  align-items: flex-end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-start {
  justify-content: start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-1 {
  flex: 1;
}

.flex-colOnMob {
  display: flex;

  @include Responsive("mobile") {
    flex-direction: column;
  }
}

.justify-end-start-tab {
  display: flex !important;
  justify-content: flex-end;

  @include Responsive("tab") {
    justify-content: flex-start !important;
  }
}

.justify-start-center-tab {
  display: flex !important;
  justify-content: flex-start;
  @include Responsive("tab") {
    justify-content: center !important;
  }
}

.justify-end-start-mob {
  display: flex !important;
  justify-content: flex-end;
  text-align: right;

  @include Responsive("mobile") {
    justify-content: flex-start !important;
    text-align: left;
  }
}

.justify-center-start-mob {
  display: flex !important;
  justify-content: center;

  @include Responsive("mobile") {
    justify-content: flex-start !important;
  }
}

.fs-14--noMob {
  font-size: 14px !important;

  @include Responsive("mobile") {
    font-size: 12px !important;
    letter-spacing: -0.6px;
  }
}

.hover-scale {
  cursor: pointer;
}

.hover-scale:hover {
  transform: scale(1.02);
}

.link {
  color: $blue;
  font-weight: 500;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
    transform: scale(1.002);
  }
}

.c-primary {
  color: $blue !important;
}

.c-white {
  color: white !important;
}

.c-error {
  color: $red !important;
}

.c-success {
  color: $success !important;
}

.c-gray {
  color: #8e8e8e !important;
}

.c-lightgray {
  color: #c4c4c4 !important;
}

.c-warning {
  color: $yellow;
}

.c-orange {
  color: $yellow;
}

.c-black {
  color: black !important;
}

.c-dark-orange {
  color: $orange !important;
}

.bg-primary {
  background-color: $blue !important;
}

.bg-primary--opaque {
  background-color: $blue_opaque !important;
}

.bg-primary-light {
  background-color: $light_blue !important;
}

.bg-dark-blue {
  background-color: $dark_blue !important;
}

.bg-light-yellow {
  background-color: $light_yellow !important;
}
.bg-orange {
  background-color: $orange_opaque !important;
}
.bg-green--opaque {
  background-color: $green_opaque;
}

.bg-red--opaque {
  background-color: $red_opaque;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-lightgray {
  background-color: $light_gray;
}

.bg-darkgray {
  background-color: $darkgray;
}

.bg-error {
  background-color: $red;
}

.bg-purple {
  background-color: $purple;
}

.bg-purple-opaque {
  background-color: $purple_opaque;
}

.bg-white {
  background-color: white !important;
}
.bg-green {
  background-color: $green !important;
}
.bg-success {
  background-color: $success !important;
}
.bg-opaque {
  background-color: #00000010;
}

.border-orange {
  border: 1px solid $orange !important;
}
.underline {
  text-decoration: underline;
  text-underline-position: below;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.small {
  font-size: small;
}

.text-center {
  text-align: center !important;
}

.text-right-center--mob {
  text-align: right;
  @include Responsive("mobile") {
    text-align: center !important;
  }
}

.text-left {
  text-align: left;
}

.w-full {
  width: 100% !important;
}

.w-80p {
  width: 80%;
}

.w-90p {
  width: 90%;
  margin: 0 auto;
}

.h-full {
  height: 100% !important;
}

.h-auto {
  height: fit-content !important;
  min-height: fit-content !important;
}

.text-right {
  text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

button {
  cursor: pointer;
}

.bordered {
  .MuiSelect-icon {
    width: 0.8em !important;
    margin-right: 10px !important;
    height: 0.8em !important;
    color: black !important;
    border: 2px solid black !important;
    border-radius: 100% !important;
  }

  &.none {
    .MuiSelect-icon {
      display: none !important;
    }
  }
}

.question-mark {
  height: 30px;
  width: 30px;
  color: white;
  font-size: 20px;
  font-weight: 600;
  background: #444444;
  text-align: center;
  display: grid;
  place-items: center;
  border-radius: 100%;

  &.responsive {
    @include Responsive("tab") {
      display: none;
    }
  }
}

.pt-header {
  padding-top: 60px;
}

.mt-header {
  margin-top: 75px !important;
}

.bg-gray {
  background-color: $bg_gray !important;
}

.border-t1 {
  border-top: 1px solid #eee;
}

.column-center {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text-nowrap {
  white-space: nowrap !important;
}

.leading-tight {
  line-height: 90%;
}
.leading-snug {
  line-height: 110%;
}

.tracking-tight {
  letter-spacing: -0.5px !important;
}

.tracking-tighter {
  letter-spacing: -1px !important;
}

.tracking-wide {
  letter-spacing: 0.5px;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: none;
}

.user-select-none {
  user-select: none;
}

.banner-container {
  height: 329px;
  width: 100vw;
  overflow: hidden;
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text-truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.break-word {
  word-break: break-word !important;
}

.object-cover {
  object-fit: cover !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflowY-hidden {
  overflow-y: hidden !important;
}

.overflowY-auto {
  overflow-y: auto;
}

.overflowX-auto {
  overflow-x: auto;
}

.overflowX-hidden {
  overflow-x: hidden !important;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute !important;
}

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}

.top-0 {
  top: 0;
}

.-top-1\/2 {
  top: -50%;
}

.left-0 {
  left: 0;
}

.container {
  width: min(90%, 1300px);
  margin: 0 auto;
  max-width: 1300px;

  @include Responsive("tab") {
    max-width: 96%;
  }
}

.container-medium {
  width: min(90%, 1600px);
  margin: 0 auto;
  max-width: 1600px;

  @include Responsive("mobile") {
    max-width: 96%;
  }
}

.container-big {
  width: 98%;
  max-width: 1600px;
  margin: 0 auto;
}

.border {
  border: 1px solid #eee;
}

.border-gray {
  border: 1px solid #d2d4da;
}

.border-error {
  border: 1px solid $red !important;
}

.border-b {
  border-bottom: 1px solid #eee;
}

.border-l {
  border-left: 1px solid #eee;
}

.border-t {
  border-top: 1px solid #eee;
}

.border-r {
  border-right: 1px solid #eee;
}

.border-dashed {
  border: 2px dashed #eee !important;
}

.border-none {
  border: none !important;
}

.border-primary {
  border: 2px solid $blue !important;
}

.border-primary-x {
  border-left: 2px solid $blue !important;
  border-right: 2px solid $blue !important;
}

.border-primary-bottom {
  border-bottom: 2px solid $blue !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.border-right-gray {
  border: 1px solid #d2d4da;
}

.no-border-right {
  border-right: none;
}

.small-table {
  @include Responsive("mobile") {
    th {
      font-size: 12px !important;
      white-space: nowrap;
    }

    td {
      font-size: 10px !important;
    }
  }
}

.ghostInput {
  border: none;
  outline-width: 0px;

  &:focus {
    border: none;
    outline-width: 0px;
  }
}

.input {
  border: 1px solid #eee !important;
  border-radius: 6px !important;
  outline: none !important;
  transition: all 0.2s !important;
  padding-left: 5px;
  font-size: 14px !important;
  box-shadow: none !important;

  fieldset {
    border: none !important;
  }

  &.error {
    border: 1px solid $red !important;
    background: rgba(248, 157, 157, 0.034);
  }

  &:focus {
    border: 2px solid #4d8fce !important;
    box-shadow: 1px 2px 6px #eee !important;
  }
}

.rounded-4 {
  border-radius: 4px !important;
}

.MuiFormControlLabel-label {
  width: 100%;
}

.shadow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.textOverflow-ellipsis {
  text-overflow: ellipsis;
}

.text-shadow {
  text-shadow: 2px 2px 4px #000000;
}

.pac-container {
  z-index: 1500 !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.transform-none {
  text-transform: none !important;
}

.c-title {
  color: #444444;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 19px;
  max-height: 55px;
  overflow: hidden;
}

.vertical-align-middle {
  vertical-align: "middle" !important;
}
.text-italic {
  font-style: italic !important;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.rounded-top-10 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.c-nav-icons {
  color: #404252 !important;
}

.w-fit {
  width: fit-content;
}

.divide-y-darkgray > *:not(:first-child) {
  border-top: 1px solid $darkgray; /* Example color, adjust as needed */
}

.-translate-y-1\/2 {
  transform: translateY(-50%);
}
// auth-card css

.auth-card {
  width: 500px;
  max-width: 30vw;
  height: auto;
  background: white;
  margin: auto;
  border-radius: 25px;
  min-height: 54vh;
  max-height: max(64vh, 1000px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  img {
    height: 45px;
    width: auto;
  }

  &.scroll {
    max-height: 92vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  @include Responsive("tab") {
    height: auto !important;
    border-radius: 0px;
    width: 100vw;
    max-height: max(70vh, 1000px);
    max-width: 100dvw;
    background: #f3f7fc;
  }
}

// Swiper css

.swiper-wrapper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  margin-bottom: 30px;
}

.swiper {
  .swiper-button-prev {
    top: initial !important;
    top: 10%;
    bottom: 0 !important;
    z-index: 50;
    left: 43% !important;
    color: $blue;

    @include Responsive("tab") {
      left: 30% !important;
    }
  }

  .swiper-button-next {
    top: initial !important;

    bottom: 0 !important;
    z-index: 50;
    right: 43% !important;
    color: $blue;

    @include Responsive("tab") {
      right: 30% !important;
    }
  }

  &.half {
    .swiper-button-prev {
      left: 38% !important;

      @include Responsive("tab") {
        left: 30% !important;
      }
    }

    .swiper-button-next {
      right: 38% !important;

      @include Responsive("tab") {
        right: 30% !important;
      }
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 25px !important;
    font-weight: bold;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    color: $blue;
    &.swiper-pagination-bullet-active {
      background-color: $blue;
      color: $blue;
    }
  }

  &.xs {
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin-bottom: 5px;
      background-color: $blue;
      &.swiper-pagination-bullet-active {
        background-color: $blue;
        color: $blue;
      }
    }

    .swiper-button-next:after,
    .swiper-button-prev:after {
      font-size: 20px !important;
      font-weight: bold;
    }

    .swiper-button-prev {
      left: 28% !important;

      @include Responsive("tab") {
        left: 20% !important;
      }
    }

    .swiper-button-next {
      right: 28% !important;

      @include Responsive("tab") {
        right: 20% !important;
      }
    }
  }
}

.timeline_restricted {
  .react-date-picker {
    &__wrapper {
      background: white;
    }
    &__clear-button {
      display: none;
    }
    &__calendar-button {
      order: -1;
      background: white;
      border-right: 2px solid $light_gray;
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
  .react-date-picker__inputGroup,
  input {
    background-color: white;
    border-radius: 6px;
  }

  textarea {
    padding: 12px;
  }

  .react-datepicker__input-container {
    input {
      border: 1px solid #e5e5e5;
      padding: 12px 15px;
      border-radius: 6px;
      &:focus {
        outline: none;
      }
    }
  }
  .MuiGrid-container {
    .MuiGrid-item {
      @media screen and (max-width: 1200px) {
        .react-datepicker-wrapper {
          padding-top: 0;
        }
      }
      &:first-child {
        max-width: 470px;
      }
    }
  }
}

.form_wrapper {
  .challenge_details {
    &__description,
    &__cover-banner {
      .MuiGrid-root:first-child {
        margin-bottom: 0;
      }
      .MuiGrid-root:last-child {
        margin-top: 0;
        .MuiGrid-item {
          padding-top: 2px;
          .input_dropbox__input__RF-nm {
            margin-top: 2px !important;
          }
        }
      }
    }
    &__cover-banner {
      h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }
      h3 + p {
        color: $darkgray;
        font-size: 16px;
        line-height: 19px;
        padding: 5px 0 10px 0;
      }
      .input_dropbox__input__RF-nm {
        border-radius: 4px;
      }
    }
  }

  .guideline-container {
    .css-z7rwsj-MuiGrid-root {
      .MuiGrid-item {
        &:last-child {
          > div {
            display: flex;
            align-items: center;
            gap: 6px;
            border: 1px solid #e5e5e5;
            padding: 10px 15px;
            border-radius: 6px;
            button {
              order: -1;
            }
          }
        }
      }
    }
  }
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle,
  .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    display: none;
  }
  .react-datepicker__time-container {
    width: 180px;
    .react-datepicker__time .react-datepicker__time-box {
      width: 180px;
    }
  }
}

.custom_timeline {
  padding: 0 0 120px 0;
  .react-date-picker {
    width: 50% !important;
    @media screen and (max-width: 1200px) {
      width: 100% !important;
    }
    .react-date-picker__wrapper {
      .react-date-picker__clear-button {
        color: $gray;
      }
      .react-date-picker__calendar-button {
        order: -1;
        color: $blue;
      }
    }
  }
}

.select-all-container {
  margin: 12px 0;
  padding: 12px;
  background: rgba($blue, 0.2);
  border: 1px solid $blue;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  span {
    margin-left: 5px;
    color: $green;
  }
}

.text-danger {
  color: #fd5959;
}

.bold {
  font-weight: bold;
}

.description * {
  font-size: 13px; /* Set to your desired size */
  line-height: 16px !important;
  margin: 0; /* Reset margins for uniformity */
}
.hidden {
  display: none !important;
}

.react-datepicker-popper {
  z-index: 11 !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-decoration-none {
  text-decoration: none;
}

div.uppy-Informer-animated p {
  overflow: hidden !important;
}

.subtitle {
  flex: 1 1 0;
  min-width: 0;
}
