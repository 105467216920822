$breakpoints: (
  "tab": 1200px,
  "mobile": 500px,
) !default;

$margins: 30;
$baseFontSize: 16;

@mixin Responsive($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin Spacings {
  @for $i from 0 through $margins {
    .mt-#{$i} {
      margin-top: #{5 * $i}#{"px"} !important;
    }
    
    body .mt-#{$i}--mob {
      @include Responsive("mobile") {
        margin-top: #{5 * $i}#{"px"} !important;
      }
    }

    .mb-#{$i} {
      margin-bottom: #{5 * $i}#{"px"} !important;
    }

    body .mb-#{$i}--mob {
      @include Responsive("mobile") {
        margin-bottom: #{5 * $i}#{"px"} !important;
      }
    }

    .ml-#{$i} {
      margin-left: #{5 * $i}#{"px"} !important;
    }

    body .ml-#{$i}--mob {
      @include Responsive("mobile") {
        margin-left: #{5 * $i}#{"px"} !important;
      }
    }

    .mr-#{$i} {
      margin-right: #{5 * $i}#{"px"} !important;
    }

    .mr-#{$i}--noMob {
      margin-right: #{5 * $i}#{"px"} !important;

      @include Responsive("mobile") {
        margin-right: 0px !important;
      }
    }

    .mx-#{$i} {
      margin-left: #{5 * $i}#{"px"} !important;
      margin-right: #{5 * $i}#{"px"} !important;
    }

    body .mx-#{$i}--mob {
      @include Responsive("mobile") {
        margin-left: #{5 * $i}#{"px"} !important;
        margin-right: #{5 * $i}#{"px"} !important;
      }
    }

    body .mx-#{$i}--tab {
      @include Responsive("tab") {
        margin-left: #{5 * $i}#{"px"} !important;
        margin-right: #{5 * $i}#{"px"} !important;
      }
    }

    .my-#{$i} {
      margin-top: #{5 * $i}#{"px"} !important;
      margin-bottom: #{5 * $i}#{"px"} !important;
    }

    .my-#{$i}--mob {
      @include Responsive("mobile") {
        margin-top: #{5 * $i}#{"px"} !important;
        margin-bottom: #{5 * $i}#{"px"} !important;
      }
    }

    .py-#{$i}--mob {
      @include Responsive("mobile") {
        padding-top: #{5 * $i}#{"px"} !important;
        padding-bottom: #{5 * $i}#{"px"} !important;
      }
    }

    .pt-#{$i} {
      padding-top: #{5 * $i}#{"px"} !important;
    }

    .pb-#{$i} {
      padding-bottom: #{5 * $i}#{"px"} !important;
    }

    .pl-#{$i} {
      padding-left: #{5 * $i}#{"px"} !important;
    }

    .pr-#{$i} {
      padding-right: #{5 * $i}#{"px"} !important;
    }

    .top-#{$i} {
      top: #{5 * $i}#{"px"} !important;
    }

    .left-#{$i} {
      left: #{5 * $i}#{"px"} !important;
    }

    .right-#{$i} {
      right: #{5 * $i}#{"px"} !important;
    }

    .px-#{$i} {
      padding-left: #{5 * $i}#{"px"} !important;
      padding-right: #{5 * $i}#{"px"} !important;
    }

    .py-#{$i} {
      padding-top: #{5 * $i}#{"px"} !important;
      padding-bottom: #{5 * $i}#{"px"} !important;
    }
  }
}

@mixin Sizing {
  @for $i from 10 through 50 {
    .fs-#{$i} {
      font-size: #{$i/$baseFontSize}#{"rem"} !important;
    }

    .fs-#{$i}--tab {
      @include Responsive("tab") {
        font-size: #{$i/$baseFontSize}#{"rem"} !important;
      }
    }

    body .fs-#{$i}--mob {
      @include Responsive("mobile") {
        font-size: #{$i/$baseFontSize}#{"rem"} !important;
      }
    }
  }
}

@mixin Opacity {
  @for $i from 0 through 100 {
    @if $i%10 ==0 {
      .opacity-#{$i} {
        opacity: #{$i/100};
      }

      .z-#{$i} {
        z-index: #{$i};
      }

      .mt-#{$i}vh {
        margin-top: #{$i}#{"vh"} !important;
      }
    }
  }
}

@mixin HWs {
  @for $i from 4 through 500 {
    .h-#{$i} {
      height: #{$i/$baseFontSize}#{"rem"} !important;
    }

    .h-#{$i}--noMob {
      height: #{$i/$baseFontSize}#{"rem"};

      @include Responsive("mobile") {
        height: auto;
      }
    }

    .w-#{$i} {
      width: #{$i/$baseFontSize}#{"rem"} !important;
    }

    .minh-#{$i} {
      min-height: #{$i/$baseFontSize}#{"rem"} !important;
    }

    .minw-#{$i} {
      min-width: #{$i/$baseFontSize}#{"rem"} !important;
    }

    .maxh-#{$i} {
      max-height: #{$i/$baseFontSize}#{"rem"} !important;
    }

    .maxw-#{$i} {
      max-width: #{$i/$baseFontSize}#{"rem"} !important;
    }

    @include Responsive("tab") {
      body .w-#{$i}--mob {
        width: #{$i/$baseFontSize}#{"rem"} !important;
      }
      .h-#{$i}--mob {
        height: #{$i/$baseFontSize}#{"rem"} !important;
      }
      .maxw-#{$i}--mob {
        max-width: #{$i/$baseFontSize}#{"rem"} !important;
      }
      .minh-#{$i}--mob {
        min-height: #{$i/$baseFontSize}#{"rem"} !important;
      }
      .minw-#{$i}--mob {
        min-width: #{$i/$baseFontSize}#{"rem"} !important;
      }

      .maxh-#{$i}--mob {
        max-height: #{$i/$baseFontSize}#{"rem"} !important;
      }
    }
  }

  @for $i from 0 through 100 {
    @if $i%10 ==0 {
      .gap-#{$i} {
        gap: #{$i}#{"px"} !important;
      }
    }

    @if $i%5 ==0 {
      .h-#{$i}p {
        height: #{$i}#{"%"} !important;
      }

      .h-#{$i}vh {
        height: #{$i}#{"vh"} !important;
      }

      .w-#{$i}p {
        width: #{$i}#{"%"} !important;
      }

      body .w-#{$i}p--mob {
        @include Responsive("tab") {
          width: #{$i}#{"%"} !important;
        }
      }

      .h-#{$i}p--mob {
        @include Responsive("tab") {
          height: #{$i}#{"%"} !important;
        }
      }

      .left-#{$i}p {
        left: #{$i}#{"%"} !important;
      }

      .maxw-#{$i}p {
        max-width: #{$i}#{"%"} !important;
      }

      .right-#{$i}p {
        right: #{$i}#{"%"} !important;
      }

      .rounded-#{$i} {
        border-radius: #{$i}#{"px"} !important;
      }
    }
  }
}
