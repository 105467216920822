@import '../../../helpers/styles/constants.scss';
@import '../../../helpers/styles/mixins.scss';

.dashboard__layout {
    width: 100vw;
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $bg_gray;

    .dashboard__header {
        height: 60px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        background-color: white;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1000;

        div.dashboard__headerLeft,
        div.dashboard__headerRight {
            display: flex;
            flex: 1;
            align-items: center;
            margin: 0 10px;
        }

        div.dashboard__headerRight {
            justify-content: flex-end;
        }

    }
}

.discussionBox {
    width: 100%;
    height: 500px;
    background: white;
    border-radius: 0 10px 10px 0;
    overflow-y: auto;
    overflow-x: hidden;

    .header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 9999;
        border-bottom: 1px solid #eee;
    }

    .sender {
        position: sticky;
        border-top: 1px solid #eee;
        bottom: 0;
        background: white;
        z-index: 9999;
    }
}

.desktop-90p{
    width: 90% !important;
    @include Responsive('tab') {
        width: 95%!important;
    }
}
