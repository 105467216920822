@import './constants.scss';

.react-calendar button {
    font-weight: 500;
    padding: 13px 0px;

    &.react-calendar__tile--now {
        background: $blue  !important;
        border-radius: 100% !important;
        color: white;
    }
}

div.react-date-picker__wrapper {
    border: 1px solid #eee !important;
    border-radius: 6px !important;
    padding-left: 4px !important;
    font-size: 14px !important;
    width: 100% !important;
    height: 45px !important;
    .react-date-picker__inputGroup__input {
      background: transparent !important;
      &:focus{
          outline-width: 0px !important;
          border: none !important;
      }
    }
}