@import '../../helpers/styles/constants.scss';

.imageGrid__item{
    position: relative;
    .cross{
        position: absolute;
        top: -5px;
        right:-3px;
        height: 12px;
        width: 12px;
        background-color: $red;
        color: white;
        border-radius: 100%;
        cursor: pointer;
    }
}