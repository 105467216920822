@import "../../helpers/styles/mixins.scss";

.prepr-report-module {
  .grid-lg {
    flex-basis: 40% !important;
    max-width: 40% !important;
    @include Responsive('tab') {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
    @include Responsive('mobile') {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }

  }

  .grid-md {
    flex-basis: 30% !important;
    max-width: 30% !important;
    @include Responsive('tab') {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }
    @include Responsive('mobile') {
      flex-basis: 100% !important;
      max-width: 100% !important;
    }

  }
}
