@import '../../helpers/styles/constants.scss';
@import '../../helpers/styles/mixins.scss';

.modal {
    background: white;
    margin: 7vh auto;
    position: relative;
    max-width: 70%;
    padding: 10px;
    border-radius: 4px;
    max-height: 92vh;
    overflow-x: hidden;
    overflow-y: auto;
    outline: unset;
    @include Responsive("tab"){
        max-width: 96%;
        width: 96% !important;
        max-height: auto !important;
    }
}

.dialog {
    background: white;
    position: relative;
    border-radius: 4px;
    overflow-x: hidden;
    overflow-y: auto;
}