.password-validator {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.validationTexts {
  margin-left: 0px;
  width: 100%;
}
.validationTexts .circle {
  height: 8px;
  width: 8px;
  border: 2px solid;
  border-radius: 100px;
  margin-left: 5px;
}
.validationTexts small {
  margin-left: 10px;
}
