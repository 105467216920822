@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

#root {
  overflow-x: hidden;
}
html {
  -webkit-text-size-adjust: 90%;
}
body,
* {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  overscroll-behavior-x: none;
}
*::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}
*::-webkit-scrollbar-track {
  background-color: #eee;
}
*::-webkit-scrollbar-thumb {
  background: #ccc;
}
*::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  /* -webkit-appearance: none; */
}
.css-7wkehm-MuiListItem-root.Mui-selected {
  background-color: white !important;
}
.emoji-picker-react .emoji-scroll-wrapper {
  max-height: 230px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper {
  max-width: 700px !important;
}

.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  margin: 10px !important;
}
.react-date-picker__calendar {
  z-index: 9999 !important;
}

.drawer.dark .MuiPaper-root.MuiDrawer-paper {
  background-color: #1d3349 !important;
  color: white;
}

div.Toastify__toast.Toastify__toast--close-on-click {
  padding: 5px;
  border-radius: 7px;
}

div.Toastify__toast > div.Toastify__toast-body {
  font-size: 14px;
  color: black;
  line-height: 1.2;
}

div.Toastify__toast.Toastify__toast--success.Toastify__toast--close-on-click {
  border: 1px solid #27ae60;
  background: #f3fff3;
}
div.Toastify__toast.Toastify__toast--error.Toastify__toast--close-on-click {
  border: 1px solid #e74c3c;
  background: #fdf3ec;
}

div.Toastify__toast
  > div.Toastify__toast-body
  > div.Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
  width: 30px;
  margin-right: 15px;
}

div.Toastify__toast
  > button.Toastify__close-button.Toastify__close-button--light {
  align-self: center !important;
  padding: 0px 3px;
}
