@import '../../../helpers/styles/constants.scss';

.unified-user-search {
  font-size: 14px;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  box-shadow: 0 0 3px rgba($light_gray, 0.3);
  border: 1px solid rgba($gray, 0.6);
  margin: 12px 0;
  width: 100%;
}

.unified-user-list-pagination , .unified-user-list-count-details {
  padding: 14px 0;
  display: block;
  font-size: 14px;

  .icon {
    transition: all 0.3s ease-in-out;
    color: $darkgray;
    &:hover {
      color: black;
    }
  }

  .icon-disabled {
    color: $darkgray;
    cursor: not-allowed;
  }

  .gap-4 {
    gap: 8px;
  }
}

.unified-user-list-count-details {
  color: $darkgray;
}

.text-danger{
  color: $red;
}

.email-form-unified{
  text-align: left !important;
}
