@import "../../helpers/styles/mixins.scss";
@import "../../helpers/styles/constants.scss";

@keyframes flash {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.ai-scoped {
    .flashing {
        animation: flash 4s infinite;
    }
    
    .intro-box {
        justify-content: center;
        text-align: center;
    }
    
    .intro-text {
        width: 70%;
        margin: 0 auto;
        font-weight: bold;
        font-size: 1.2rem;
        color: gray;
        text-align: center;
    
        @include Responsive("mobile") {
            width: 90%;
            font-size: .9rem;
        }
    }
    
    .gray-cloud {
        background: rgba(0, 0, 0, 0.2);
        z-index: 1;
        border-radius: 10px;
        position: absolute;
        inset: 0;
    }
    
    .no-select-cloud {
        * {
            user-select: none !important;
            pointer-events: none !important;
        }
    }
    
    .lab-panel {
        background-color: #EAF8FE;
        margin: 10px 0 !important;
        border-radius: 10px !important;
    
        &::before {
            display: none;
        }
    }
    
    .challenge-panel {
        background-color: #F0FAF0;
        margin: 10px 0 !important;
        border-radius: 10px !important;
    
        &::before {
            display: none;
        }
    }
    
    .resource-panel {
        background-color: #FAF4FE;
        margin: 10px 0 !important;
        border-radius: 10px !important;
    
        &::before {
            display: none;
        }
    }
    
    .MuiAccordionSummary-root.MuiButtonBase-root {
        padding-top: .7rem;
        padding-bottom: .7rem;
        justify-content: flex-start;
        min-height: auto !important;
    }
    
    .panel-main {
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 70%;
    
        @include Responsive("tab") {
            width: 100%;
        }
    }
    
    .image-and-title {
        align-items: center;
        display: flex;
    }
    
    .MuiAccordionSummary-content {
        display: contents;
    }
    
    .icon-margin {
        margin: auto;
        margin-right: 8px;
    }
    
    .desc-icon {
        align-self: flex-start;
        min-width: 26px;
        width: 26px;
        height: 26px;
        margin-right: 6px;
    }
    
    .panel-icon {
        align-self: flex-start;
        min-width: 33px;
        width: 33px;
        height: 33px;
    
        @include Responsive("mobile") {
            display: none;
        }
    }
    
    .panel-icon-box {
        align-self: center;
        min-width: 28px;
        width: 28px;
        height: 28px;
        border-radius: 15%;
        background: $green;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    
        img {
            max-width: 80%;
            max-height: 80%;
            object-fit: cover;
        }
    }
    
    .panel-button {
        position: absolute;
        visibility: visible;
        top: .7rem;
        right: 1rem;
    }
    
    .md-panel-button {
        @include Responsive("tab") {
            display: none !important;
        }
    }
    
    .sm-panel-button {
        display: none !important;
        position: static;
        margin-top: .2rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
    
        @include Responsive("tab") {
            display: flex !important;
        }
    }
    
    .form-field {
        div {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    
    .text-and-select {
        display: flex;
    
        .MuiGrid-item {
            max-width: 100%;
            flex-basis: 100%;
        }
    }
    
    .ai-creation-form .MuiAccordion-root {
        width: 100%;
    }
    
    .link-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
    
    .link-container,
    .video-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 10px;
        justify-content: space-between;
    }
    
    .link-title,
    .link-url,
    .video-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .video-title {
        display: block;
        margin-bottom: 1rem;
    }
    
    .link-title-box {
        width: 60%;
        display: flex;
    }
    
    .link-title {
        width: 100%;
    }
    
    .link-url {
        width: 35%;
    }
    
    .hr-style {
        width: 100%;
        border-top: 1px solid #ccc;
        margin-top: 10px;
    }
    
    .video-box {
        width: calc(50% - 10px);
        margin-bottom: 3.5rem;
        margin-right: 10px;
        position: relative;
        padding-top: calc(28.125% - 28px);
        
        iframe, .placeholder-video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    
        .placeholder-video img {
            width: 100%;
            height: 100%;
        }
    
        a {
            width: 95%;
            position: absolute;
            left: 0;
            bottom: -3rem;
        }
    }
    
    @include Responsive("tab") {
        .link-container {
            display: block;
        }
    
        .link-title-box,
        .link-url {
            width: 100%;
            margin-bottom: 5px;
        }
    }
    
    @include Responsive("mobile") {
        .video-container {
            display: block;
        }
    
        .video-box {
            width: 100%;
            padding-top: calc(56.25% - 14px);
        }
    }
    
    .strike-through {
        text-decoration: line-through;
    }
    
    .no-good-fit {
        color: $blue;
        text-decoration: underline;
        cursor: pointer;
    }
    
    .rm-image {
        width: 50%;
    
        img {
            width: 100%;
        }
        
        @include Responsive("mobile") {
            width: 100%;
        }
    }
}