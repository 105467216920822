@import '../../helpers/styles/constants.scss';
@import '../../helpers//styles/mixins.scss';

.py-chats {
    padding-top: 20px;
    padding-bottom: 20px;

    @include Responsive("tab") {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.chatContainer {
    height: 85vh;

    @include Responsive("tab") {
        border-radius: 0px;
        margin-top: -10px;
        height: 87vh;
    }

    .chats {
        height: calc(85vh - 100px);
        .message {
            width: fit-content;
            margin: 20px;
            line-height: 130%;
            color: #404252;

            @include Responsive("tab") {
                margin: 10px;
            }
        }
    }
}