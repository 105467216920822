.advance-search-header-component {
  position: relative !important;

 .advance-search-header-component--popover {
    position: absolute !important;
    right: 0 !important;
    bottom: -580px !important;
    width: 550px !important;
    height: 570px !important;
    color: black !important;
    background: white !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, .21) !important;
  }
}


