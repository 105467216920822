@import '../../helpers/styles/mixins.scss';
@import '../../helpers/styles/constants.scss';

.lineProgress{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
    &__step{
        display: flex;
        align-items: center;
        span.lineProgress__dot,
        span.lineProgress__line{
        background-color: gray;
        &.filled {
            background-color: $blue;
        }
        }
        span.lineProgress__dot{
            height: 10px;
            width: 10px;
            color:white;
        &.rounded{
            border-radius: 100%;
        }
        &.numbered{
            height: 15px;
            width:15px;
            font-size: 10px;
            display: grid;
            place-items: center;
        }
        }
        span.lineProgress__line{
            width: 100px;
            height: 2.5px;
            &.hidden{
                display: none !important;
            }
        }
    }
}

.simpleLine{
  width:"100%";
  height:36px;
  border-radius: 35px;  
  overflow: hidden;
  background-color: $gray;
  .bar{
      background-color: $green;
      color:white;
      text-align: left;
      display: flex;
      align-items: center;
      p{
          padding-left: 20px;
          font-size: 17px;
          font-weight: 500;
      }
  }
}